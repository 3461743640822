export default [
  {
    key: 'companyId',
    label: 'field.company',
    rules: 'required',
    type: 'nAsynSingleSelection',
    repository: 'company',
    selectionKey: 'id',
    selectionLabel: 'name',
  },
  {
    key: 'code',
    label: 'field.departmentCode',
    rules: 'required|max:10',
    type: 'text'
  },
  {
    key: 'name',
    label: 'field.departmentName',
    rules: 'required|max:100',
    type: 'text'
  },
  {
    key: 'isEnable',
    label: 'status.active',
    rules: '',
    type: 'checkbox',
    cols: 'auto',
  },
]
